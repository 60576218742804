import React, { FC, useEffect, useLayoutEffect, useRef } from "react";
import { MessagesContainerProps } from "./MessageContainer.types";
import { MessageRow } from "./MessageRow";
import ChoiceMessage from "../ChoiceMessage";
import { MediaRow } from "./Media/MediaRow";
import {
  ChoiceTypes,
  ComplexBlockTypes,
  EventTypes,
  Senders,
  Block,
  BlockTypes
} from "../../types";
import { InfoRow } from "./InfoRow";
import { MessagesProvider } from "./MessageProvider";
import { DateInfo } from "./DateInfo";
import {
  LoadingMessagesContainer,
  LoadingMessagesLabel,
  StyledMessagesContainer,
} from "./StyledMessageContainer";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";
import TypingIndicator from "../TypingIndicator/TypingIndicator";
import { GalleryPreview } from "./Gallery/GalleryPreview";
import { QuestionRow } from "./QuestionRow";
import { LoadingElement } from "./LoadingElement/LoadingElement";
import { useLanguageContext } from "../LanguageProvider/LanguageProvider";
import { ListPreview } from "./List/ListPreview";
import { DeliveryInformation } from "../DeliveryInformation/DeliveryInformation";
import { Feedback } from "../Feedback/Feedback";
import { MessageFooterWrapper } from "./MessageFooter";
import { ScrollBar } from "../ScrollBar/ScrollBar";
import { debounce } from "../../hooks/debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getBotEventsIds } from "./utils";
import { useHotkeys, useHotkeysContext } from "react-hotkeys-hook";

const templateFunction = () => {};
const MessagesContainer: FC<MessagesContainerProps> = ({
  isLoading,
  error,
  events,
  handleSendChoice,
  textAreaRef,
  operator,
  botAvatar,
  typingAvatar,
  postFeedback,
  loadMore,
  allMessagesFetched,
  fetchingMoreMessages,
  msgsContainerRef,
  closeChat,
  onEditResponse,
}) => {
  const { lang } = useLanguageContext();
  const { withHeader, withInput, showDebugInformation, zoomLevel } = useClientSettings();
  const eventsKeys = Object.keys(events);
  const lastElementKey = eventsKeys[eventsKeys.length - 1];

  const [listElement, setListElement] = React.useState<HTMLDivElement | null>(
    null
  );
  const prevScrollPosition = useRef<any>(null);
  const { enableScope } = useHotkeysContext();

  useEffect(() => {
    enableScope("mainWidgetScope");
  }, []);

  useHotkeys(
    "Escape",
    () => {
      if (closeChat) closeChat();
    },
    { scopes: ["mainWidgetScope"] }
  );

  useLayoutEffect(() => {
    if (listElement) {
      onScrollChange();
    }
  }, [eventsKeys.length, listElement]);

  const onScrollChange = debounce(() => {
    scrollContainer();
  });

  function scrollContainer() {
    if (prevScrollPosition.current) {
      prevScrollPosition.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      prevScrollPosition.current = null;
    } else {
      listElement?.scrollTo({
        top: listElement.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
      if (document.activeElement === textAreaRef?.current) {
        textAreaRef?.current?.focus();
      }
    }
  }

  async function onScrollTop() {
    if (!allMessagesFetched && loadMore) {
      const keyToScroll = await loadMore();
      if (keyToScroll) {
        prevScrollPosition.current =
          window.document.getElementById(keyToScroll);
      }
    }
  }

  useEffect(() => {
    if (listElement?.clientHeight && listElement?.scrollHeight && loadMore) {
      if (
        listElement.clientHeight === listElement.scrollHeight &&
        !fetchingMoreMessages &&
        !allMessagesFetched &&
        !isLoading
      ) {
        loadMore();
      }
    }
  }, [fetchingMoreMessages, listElement, allMessagesFetched, isLoading]);

  if (isLoading) {
    return (
      <LoadingElement
        withHeader={withHeader}
        withInput={withInput}
        botAvatar={botAvatar || ""}
      />
    );
  }
  if (error) <div>error...</div>;

  return (
    <StyledMessagesContainer
      withHeader={withHeader}
      withInput={withInput}
      ref={msgsContainerRef}
      wbbportal={Boolean(loadMore)}
      zoomLevel={zoomLevel}
    >
      <ScrollBar refElement={setListElement} onScrollTop={onScrollTop}>
        {loadMore && (
          <LoadingMessagesContainer>
            {fetchingMoreMessages && !allMessagesFetched ? (
              <LoadingMessagesLabel>
                Loading more message{" "}
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              </LoadingMessagesLabel>
            ) : (
              <LoadingMessagesLabel>All messages loaded</LoadingMessagesLabel>
            )}
          </LoadingMessagesContainer>
        )}
        {eventsKeys
          .filter((eKey) => {
            return events[eKey][0] ? !events[eKey][0].draft : true;
          })
          .map((eventKey, eventIndex) => {
            return (
              <div id={eventKey}>
                {events[eventKey].map((event: any, index: number) => {
                  const isFirstMessage = index === 0;
                  const isOperatorMsg =
                    event.deliveryInfo?.sender === operator ||
                    (event.deliveryInfo?.sender === Senders.AGENT &&
                      operator === Senders.BOT);
                  const showAvatar = !isOperatorMsg && isFirstMessage;
                  const showDeliveryInfo =
                    index === events[eventKey].length - 1;
                  const isChoiceQuestion =
                    event?.meta?.choiceType === ChoiceTypes.QUESTION;
                  const isGallery =
                    event?.block?.meta?.complexBlockType ===
                    ComplexBlockTypes.GENERIC;

                  const isFirstEventMsg = eventIndex === 0 && isFirstMessage;
                  const debugInfo =
                    showDebugInformation && isFirstMessage
                      ? events[eventKey][0]?.parsedText
                      : null;
                  const key = eventKey + "-" + index;
                  const avatar = event?.icon ? event.icon : botAvatar;

                  const eventsList = events[eventKey];
                  const botEventIds: string[] = getBotEventsIds(eventsList);

                  const kbBlocks = events[eventKey][0]?.evaluatedBlocks ? events[eventKey][0].evaluatedBlocks.filter((block: Block) => block.type == BlockTypes.KNOWLEDGE) : [];

                  const MsgFooter = (
                    <MessageFooterWrapper isOperatorMsg={isOperatorMsg}>
                      <DeliveryInformation />
                      {event.deliveryInfo?.sender === Senders.BOT ? (
                        <Feedback
                          eventId={botEventIds}
                          feedback={event.feedback}
                          postFeedback={postFeedback}
                        />
                      ) : null}
                    </MessageFooterWrapper>
                  );

                  return (
                    <div key={key + "main"}>
                      <DateInfo
                        lang={lang}
                        lastEvent={events[lastElementKey]}
                        eventTime={event.deliveryInfo.timestamp}
                        isFirstMessage={isFirstEventMsg}
                        key={key + "-date-info"}
                      />
                      <MessagesProvider
                        operator={operator}
                        isOperatorMsg={isOperatorMsg}
                        showAvatar={showAvatar}
                        showDeliveryInfo={
                          event.type === EventTypes.TYPING
                            ? false
                            : showDeliveryInfo
                        }
                        botAvatar={avatar || ""}
                        status={event?.deliveryInfo}
                        debugInfo={debugInfo}
                        knowledgeBlocks={kbBlocks}
                        key={key + "-message-provider"}
                        typingAvatar={loadMore ? avatar : typingAvatar || ""} // if it is logicdialog use user avatar for typing indicator
                        events={event?.block?.events}
                        isPortal={Boolean(loadMore)}
                        sender={event.deliveryInfo?.sender}
                        blockId={event?.block?._id}
                        onEditResponse={onEditResponse}
                      >
                        {
                          {
                            ["complex"]: isGallery ? (
                              <GalleryPreview
                                key={key}
                                content={event?.block?.content}
                                onSend={handleSendChoice}
                                msgFooter={showDeliveryInfo ? MsgFooter : null}
                              />
                            ) : (
                              <ListPreview
                                key={key}
                                content={event?.block?.content}
                                onSend={handleSendChoice}
                                msgFooter={showDeliveryInfo ? MsgFooter : null}
                              />
                            ),
                            ["text"]: (
                              <MessageRow
                                key={key}
                                content={event?.block?.content?.text}
                                msgFooter={showDeliveryInfo ? MsgFooter : null}
                              />
                            ),
                            ["select"]: (
                              <MessageRow
                                content={event?.block?.content?.text}
                                key={key}
                                msgFooter={showDeliveryInfo ? MsgFooter : null}
                              >
                                {![Senders.AGENT, Senders.BOT].includes(
                                  operator
                                ) ? (
                                  !isChoiceQuestion ? (
                                    lastElementKey === eventKey && (
                                      <ChoiceMessage
                                        onSend={
                                          handleSendChoice || templateFunction
                                        }
                                        choices={
                                          event?.block?.content?.items?.[0]
                                            ?.choices
                                        }
                                        key={key + "-choice"}
                                      />
                                    )
                                  ) : (
                                    <QuestionRow
                                      content={event?.block?.content}
                                      onSend={
                                        handleSendChoice || templateFunction
                                      }
                                    />
                                  )
                                ) : null}
                              </MessageRow>
                            ),
                            ["attachments"]: (
                              <MediaRow
                                key={key}
                                content={event?.block?.content}
                                fileName={event?.block?.fileName}
                                msgFooter={showDeliveryInfo ? MsgFooter : null}
                              />
                            ),
                            ["intervention-info"]: (
                              <InfoRow
                                key={key + "-additional-info"}
                                content={event?.content?.info}
                              />
                            ),
                            ["switch-language"]: (
                              <InfoRow
                                key={key + "-additional-info"}
                                content={event?.content?.info}
                              />
                            ),

                            ["info"]:
                              operator !== Senders.USER ? (
                                <InfoRow
                                  href={event?.content?.href}
                                  key={key + "-additional-info"}
                                  content={event?.content?.info}
                                />
                              ) : null,
                          }[event.type as string]
                        }
                      </MessagesProvider>
                    </div>
                  );
                })}
              </div>
            );
          })}
        <TypingIndicator
          key={`wbb-typing-indicator`}
          lastEvent={events[lastElementKey]}
        />
      </ScrollBar>
    </StyledMessagesContainer>
  );
};

export default MessagesContainer;
