import React, { FC } from "react"
import { AvatarProps } from "./Avatar.types"
import styled, { css } from "styled-components"

const Avatar: FC<AvatarProps> = ({ src, alt, display = true, chatAvatar, size, role, crossOrigin }) => {
    return display ? <Img src={src} alt={alt} chat={chatAvatar} size={size} role={role} {...(crossOrigin ? {crossOrigin} : {})}  /> : <Placeholder role={role}/>

}
export default Avatar;



const Img = styled.img<{ chat?: boolean; size?: string }>`
    width: ${({ chat }) => chat ? '40px' : 'auto'};
    height: 40px;
    border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.main};
    ${props => props.size && css`width: ${props.size}; height: ${props.size};`}
`;

export const StartButtonIcon = styled(Img) <{ shrink?: boolean, showLeft?: boolean }>`
    height: 36px;
    width: 36px;
    border-radius: ${({ theme: { additionalStyles: { bordersRadius: main } }, showLeft, shrink }) => showLeft ?
        shrink ? `${main.main} ${main.main} ${main.main} 0px;` : `${main.main}  ${main.main} 0px 0px;`
        :
        shrink ? `${main.main} ${main.main} 0px ${main.main};` : `${main.main}  0px 0px ${main.main};`}
    border: ${({ theme }) => `1px solid ${theme.colors.primary.main}`};
    ${({ shrink }) => !shrink &&
        css`
            border-right: none;
            margin: -14px;
            padding-right: 14px;
        `}
`;

const Placeholder = styled.div` 
    width: 45px;
    height: 0px;
    opacity: 0;
`
