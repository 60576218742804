import React, {  PropsWithChildren, useContext, useEffect, useRef } from "react";
import DebugInfoOverlap from "../DebugInfo/DebugInfoOverlap";
import LLMDebugInfoOverlap from "../LLMDebugInfo/LLMDebugInfoOverlap";
import TopOverlapBar from "./IframeTopbar";
import { CSSTransition } from 'react-transition-group'
import { RasaDebugInfo, Block } from "../../types";
import { CLOSE_OVERLAP, EmptyOverlap, FocusLockDiv, OverlapContainer, OverlapWrapper, SHOW_OVERLAP } from "./StyledOverlap";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";
import IframeComponent from "../Iframe";
import LocationMap from "../LocationMap/LocationMap";
import { IframeType, LLMDebugType, LocationFormType, OpenOverlapProps, OverlapComponentTypes, OverlapElementProps, OverlapProviderProps, UploadFileType } from "./OverlapProvider.types";
import FocusLock from "react-focus-lock";
import {  useHotkeys, useHotkeysContext } from "react-hotkeys-hook";

const UploadFileOverlap = React.lazy(() => import("../UploadFile/UploadFileOverlap"))

const { DebugInfo, Iframe, UploadFile, LocationForm, Knowledge } = OverlapComponentTypes;

const OverlapContext = React.createContext<OverlapProviderProps>({
    onOpen: ({ }) => { },
    sendMessage: () => {}
});

const OverlapProvider = ({ children, componentTypeProp, propsProp, onClose, sendMessage }: PropsWithChildren<{
    componentTypeProp: OverlapComponentTypes | null;
    propsProp: IframeType | LocationFormType | null;
    onClose?: () => void;
    sendMessage: (text: string) => void
}>) => {
    const nodeRef = useRef<HTMLDivElement>(null)
    const { fullScreen, logicdialogPreview } = useClientSettings()

    const [open, setOpen] = React.useState(false);
    const [componentType, setComponentType] = React.useState<OverlapComponentTypes | null>(null);
    const [props, setProps] = React.useState<OverlapElementProps | null>(null)

    useEffect(() => {
        if (componentTypeProp && propsProp) {
            onOpen({ componentType: componentTypeProp, props: propsProp })
        } else {
            handleClose()
        }
    }, [componentTypeProp, setProps])

    function onOpen({ componentType, props }: OpenOverlapProps) {
        setOpen(true);
        setComponentType(componentType);
        if (componentType) setProps({ [componentType]: props });
    }

    function handleClose() {
        setOpen(false)
        setTimeout(() => {
            setProps(null)
            setComponentType(null);
            if (onClose) onClose()
        }, 300);
    }

    function onEnter() {
        if (nodeRef.current) {
            nodeRef.current.className = SHOW_OVERLAP
        }
    }

    function onExiting() {
        if (nodeRef.current) {
            nodeRef.current.className = CLOSE_OVERLAP
        }
    }

    function handleSaveLocation(text: string) {
        sendMessage(text)
        handleClose()
    }

    const disableCloseButton = componentType === Iframe && (props?.[Iframe] as IframeType)?.canGoBack

    const ariaLabel = {
        [Iframe]: 'Iframe',
        [LocationForm]: 'wbb-location-form',
        [UploadFile]: 'wbb-upload-file-modal',
        [DebugInfo]: 'wbb-debug-information',
        [Knowledge]: 'wbb-llm-debug-information'
    }[componentType || Iframe]

    const title = {
        [Iframe]: '',
        [LocationForm]: 'Select location',
        [UploadFile]: 'Upload file',
        [DebugInfo]: 'Debug information',
        [Knowledge]: 'Used Knowledge'
    }[componentType || Iframe]


    return (
        <OverlapContext.Provider value={{ onOpen, sendMessage }}>
            <link rel="stylesheet" href="https://unpkg.com/leaflet/dist/leaflet.css" integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossOrigin=""/>
            {children}
            <CSSTransition
                in={open}
                timeout={250}
                onEnter={onEnter}
                onExit={onExiting}
                unmountOnExit={true}
            >
                <OverlapWrapper fullScreen={fullScreen} >
                    <React.Suspense fallback={< ></>}>
                            <OverlapContainer ref={nodeRef} role="dialog" aria-modal={true} aria-labelledby={ariaLabel}>
                                <FocusLock as={FocusLockDiv} disabled={logicdialogPreview}>
                                    <OverlapContainerWithEscape escapeMethod={handleClose}>
                                        <>
                                            <TopOverlapBar onClick={handleClose} disableClose={disableCloseButton} title={title} titleId={ariaLabel} />
                                            <EmptyOverlap data-logicdialog={logicdialogPreview}>
                                                {componentType === DebugInfo && (
                                                    <DebugInfoOverlap {...(props?.[DebugInfo] as RasaDebugInfo || {})} />
                                                )}
                                                {componentType === Knowledge && (
                                                    <LLMDebugInfoOverlap 
                                                        knowledgeBlocks={(props?.[Knowledge] as LLMDebugType)?.knowledgeBlocks as Block[] || []} 
                                                        onEditResponse={(props?.[Knowledge] as LLMDebugType)?.onEditResponse} />
                                                )}
                                                {componentType === Iframe && (<IframeComponent src={(props?.[Iframe] as IframeType)?.src || ''} />)}
                                                {componentType === LocationForm && (<LocationMap {...(props?.[LocationForm] as LocationFormType)} onSaveLocation={handleSaveLocation} />)}
                                                {componentType === UploadFile && <UploadFileOverlap {...(props?.[UploadFile] as UploadFileType)} onClose={handleClose} />}
                                            </EmptyOverlap>
                                        </>
                                    </OverlapContainerWithEscape>
                                </FocusLock>
                            </OverlapContainer>
                    </React.Suspense>
                </OverlapWrapper>
            </CSSTransition>
        </OverlapContext.Provider>
    );
};

export default OverlapProvider;

export const useOverlapContext = () => {
    return useContext(OverlapContext);
};


const OverlapContainerWithEscape = ({ children, escapeMethod }: { children: React.ReactNode, escapeMethod: any}) => {
    const { enableScope, disableScope } = useHotkeysContext();

        useEffect(() => {
        disableScope('mainWidgetScope')
        enableScope('overlapFrameScope')
          return () => {
            disableScope('overlapFrameScope')
            enableScope('mainWidgetScope')
          }
       },[])

    useHotkeys('Escape', () => {
        escapeMethod()
    }, { scopes: ['overlapFrameScope'] })
    return (<>{children}</>)
}
