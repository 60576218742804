import styled from "styled-components";

export const SrOnly = styled.span`
 clip: rect(0 0 0 0);
 clip-path: inset(50%);
 height: 1px;
 width: 1px;
 overflow: hidden;
 position: absolute;
 white-space: nowrap;
`;