import React from "react"
import DebugInfo from "../../DebugInfo/DebugInfo"
import LLMDebugInfo from "../../LLMDebugInfo/LLMDebugInfo"
import { EditResponse } from "./EditResponse"
import styled from "styled-components"
import IconButton from "../../IconButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronsUp, faChevronsDown } from "@fortawesome/pro-light-svg-icons"
import { ButtonColors, ButtonSizes, ButtonVariants } from "../../Button/Button.types"
import { useMessagesContext } from "../MessageProvider"
import { Senders } from '../../../types';

export const AgentActions = () => {
    const { debugInfo, blockId, onEditResponse } = useMessagesContext()

    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen(!open)
    }

    if (!debugInfo && (!blockId || !onEditResponse)) {
        return null
    }

    return (
        <PanelWrapper >
        <StyledActionsPanel onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            <Wrapper>
                <IconButton variant={ButtonVariants.text} color={ButtonColors.tertiary} size={ButtonSizes.small} onClick={handleToggle}>
                    <FontAwesomeIcon icon={open ? faChevronsUp : faChevronsDown}  />
                </IconButton>
                {open && (
                    <>
                        <DebugInfo />
                        <EditResponse />
                        <LLMDebugInfo />
                    </>)}
            </Wrapper>
        </StyledActionsPanel>
        </PanelWrapper>
    )
}

export const getJustification = ( showDebugInformation: boolean, onEditResponse : any, sender : any, operator : any ) => { 
    const allowDebug = ( showDebugInformation || typeof onEditResponse === "function" );
    // so, we are an agent, and the debug information is shown... 
    if ( operator === Senders.BOT && sender === Senders.BOT && allowDebug ) { 
        // in this case we want the justification to be "flex-end" because the agent message will be on the right
        // but the agent actions is also on the right. 
        return "flex-end";
    } else if ( sender === Senders.BOT && operator === Senders.USER && allowDebug ) { 
        // we are using it as an end user, but the bot has sent a message we wish to debug, therefore - space-between.
        return "space-between";
    } else { 
        return sender === operator ? "flex-end" : "flex-start"
    }
}

const PanelWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
`;
const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 2px;
gap: 4px;
& > button > svg  {
 width: 14px;
 height: 14px;
}
& > div > button > svg  {
    width: 14px;
    height: 14px;
   }
`

export const StyledActionsPanel = styled.div<{}>`
    display: flex;
    border: none;
    font-family: inherit;
    background: ${({ theme }) => theme.colors.neutral.outline};
    border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.main};
    align-items: center;
    padding: 2px;
    height: fit-content;
`;

interface AgentActionsWrapperProps {
    justification?: string;
}

export const AgentActionsWrapper = styled.div<AgentActionsWrapperProps>`
    display: flex;
    justify-content: ${({ justification } ) => { return justification } };
    width: 100%;
    gap:  8px;
    padding: 0px 0px 0px 6px;
`