import styled, { css, keyframes } from "styled-components";
import { StyledMessageProps } from "./MessageContainer.types";
import { HEADER_HEIGHT } from "../Header/StyledHeader";
import { applyShadow } from "../../inner-utils";
import { Colors } from "../ThemeProvider/ThemeProvider.types";

export const fadeInLeft = keyframes`
0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

export const fadeInRight = keyframes`
0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

export const StyledMessageRow = styled.div<StyledMessageProps>`
    display: flex;
    justify-content: ${(props) => props['data-operator'] ? 'flex-end' : 'flex-start'};
    gap: ${props => props?.zoomLevel && props?.zoomLevel > 2 ? '4px' : '8px'};
    padding-top: ${props => props?.zoomLevel && props?.zoomLevel > 2 ? '4px' : '8px'};
    padding-bottom: ${props => props?.zoomLevel && props?.zoomLevel > 2 ? '4px' : '8px'};
    animation: ${props => props['data-operator'] ? fadeInRight : fadeInLeft} 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const TextMessageContainer = styled.div<{
}>`
    max-width: 75%;
`;

export const PersistSelectMessageContainer = styled.div<{
}>`
    width: 100%;
`;


export const QuestionWrapper = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  `

export const getBorderRadiusForOperator = (radius: string, operator?: boolean): string => {
  if (operator) {
    return `${radius} 0px ${radius} ${radius}`
  }
  return `0px ${radius} ${radius} ${radius}`
}

const getMessageBackground = ({ secondary, primary, advanced }: Colors, props: StyledMessageProps): string => {
  if (props['data-operator']) {
    return advanced?.senderMessage || secondary.main;
  }
  return advanced?.receiverMessage || primary.main;
}

// PS, the margin : -20px in the code below is because the pre-wrap style automatically adds some padding that you can't control. 
// We need the pre-wrap to help links that are long wrap to the next line and it needs to be a `pre-<something>` so that it handles \n's 
// in the text of the responses. This is the only solution i could find that made it look okay. 
export const MsgBubble = styled.div<StyledMessageProps>`
    border: ${({ theme, ...props }) => props['typing-indicator'] ? 'none' : `1px solid ${getMessageBackground(theme.colors, props)}`};
    border-radius:  ${({ theme, ...props }) => getBorderRadiusForOperator(theme.additionalStyles.bordersRadius.main, props['data-operator'])};
    background: ${({ theme, ...props }) => getMessageBackground(theme.colors, props)};
    color: ${({ theme, ...props }) => props['data-operator'] ? theme.colors.secondary.mainContrast : theme.colors.primary.mainContrast};
    line-height: 18x;
    padding: 6px 12px 6px 12px;
    font-size: 14px;
    white-space: pre-wrap; /* CSS3 */    
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */    
    white-space: -o-pre-wrap; /* Opera 7 */    
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-weight: 400;
    ${({ select }) => select && css`
    width: 100%;
    ` }

    p, ol, li { 
      margin: 0px;
    }

    ul { 
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px 0px 0px 18px;
    }

    li { 
      white-space: normal;
    }

    ol { 
      padding-left: 20px;
    }

    h1, h2, h3, h4, h5 { 
      font-size: 14px;
    }

    a { 
      color: ${({ theme, ...props }) => props['data-operator'] ? theme.colors.secondary.mainContrast : theme.colors.primary.mainContrast};
    }
}
`

export const ComplexMsgBubble = styled(MsgBubble)`
  border-radius: 0px 0px 12px 12px;
  & > h5 {
    margin: 0px;
    font-weight: 400;
  }
`

export const INPUT_CONTAINER_HEIGHT = '53px'

export const StyledMessagesContainer = styled.div<{
  withHeader?: boolean
  withInput?: boolean;
  wbbportal?: boolean;
  tabindex?: string;
  zoomLevel?: number;
}>`
border-bottom: none;
border-top: none;
  height: ${({ withHeader, withInput, wbbportal, zoomLevel }) => {
    let additionalPadding = "0px";
    if ( zoomLevel && zoomLevel > 2 ) { 
      additionalPadding = "28px";
    }
    if(wbbportal) {
      return css`
      calc(100% - 140px);
      `
    }
    if (withHeader && withInput) {
      return css`
    calc(100% - ${HEADER_HEIGHT} - ${INPUT_CONTAINER_HEIGHT} + ${additionalPadding});
    `
    }
    if (withHeader && !withInput) {
      return css`
    calc(100% - ${HEADER_HEIGHT} + ${additionalPadding} );
    `
    }
    if (withInput && !withHeader) {
      return css`
     calc(100% - ${INPUT_CONTAINER_HEIGHT} + ${additionalPadding} );
    `
    }
    return `100%`
  }}
    padding: 0px;
    overflow-x: none;
`

export const FooterWrapper = styled.div<StyledMessageProps>`
display: flex;
height: 19px;
margin-top: 10px;
justify-content: ${(props) => props['data-operator'] ? 'flex-end' : 'flex-start'};

`

const getSelectButtonsBorderRadius = (radius: string, child?: number): string => {
  if (child === 1) {
    return `0px ${radius} 0px 0px`
  } else if (child === 2) {
    return `0px  0px ${radius} ${radius}`
  }
  return `0px`
}

export const SelectChoicesWrapper = styled.div<{ multiple?: boolean }>`
width: 100%;
display: flex;
flex-direction: column;
margin-top: 10px;
${({ multiple }) => multiple && css`
border-radius: ${({ theme }) => `0px  ${theme.additionalStyles.bordersRadius.main} ${theme.additionalStyles.bordersRadius.main} ${theme.additionalStyles.bordersRadius.main}`};
// box-shadow: ${({ theme }) => applyShadow(theme.additionalStyles.elevation)};
& > button:not(:last-child):not(:nth-child(1))  {
  border-radius: ${({ theme }) => getSelectButtonsBorderRadius(theme.additionalStyles.bordersRadius.main)};
  border-bottom: none;
  box-shadow: none;
}
 >:first-child {
  border-radius: ${({ theme }) => getSelectButtonsBorderRadius(theme.additionalStyles.bordersRadius.main, 1)};
  border-bottom: none;
  box-shadow: none;
}
 >:last-child {
  border-radius: ${({ theme }) => getSelectButtonsBorderRadius(theme.additionalStyles.bordersRadius.main, 2)};
  box-shadow: none;
}
`}
`

export const LoadingMessagesContainer = styled.div`
width: 100%;
justify-content: center;
display: flex;
`;
export const LoadingMessagesLabel = styled.span`
text-align: center;
`
