import React, { FC } from "react";
import { MsgBubbleIcon } from "../icons";
import { PopupButtonProps } from "./PopupButton.types";
import { OpenPopupLabel, OpenPopupText, StyledPopupButtonDiv } from "./StyledPopupButton";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";
import Avatar from "../Avatar";
import { StartButtonIcon } from "../Avatar/Avatar";
import { SrOnly } from "../SrOnly/SrOnly";

const PopupButton: FC<PopupButtonProps> =
  ({ open, onToggle, text, endIcon, startIcon = <MsgBubbleIcon />, customIcon }) => {
    const { isMobileOrSmallScreen, zoomLevel, showLeft, popupSize } = useClientSettings()
    let icon = startIcon;
    if ( popupSize ) { 
      icon = <MsgBubbleIcon popupSize={popupSize}/>
    }
    if (customIcon?.src) {
      icon = customIcon?.outside ?
        <StartButtonIcon  showLeft={showLeft} src={customIcon.src} role='presentation' shrink={isMobileOrSmallScreen} />
        :
        <Avatar src={customIcon.src} size='20px' role='presentation' />
    }

    // mobile button should has only custom icon (if its exist) without padding
    const customButtonPadding = customIcon?.outside && isMobileOrSmallScreen ? `
      padding: 0px;
      ${!isMobileOrSmallScreen && `
        padding-right: 10px;
      `}
      ` : ``;

    return (
      <StyledPopupButtonDiv open={!!open} showLeft={showLeft} shrinkButton={isMobileOrSmallScreen} zoomLevel={zoomLevel} popupSize={popupSize || "small"} customButtonPadding={customButtonPadding}>
        <button className={`menu-open ${open ? '' : 'menu-open-start'}`} name="menu-open" id="menu-open" onClick={onToggle}>
          <SrOnly>Use this chatbot if you need help</SrOnly>
          <OpenPopupLabel popupSize={popupSize || "small"}>
            {icon && icon}
            { !isMobileOrSmallScreen && <OpenPopupText popupSize={popupSize || "small"}>{text}</OpenPopupText> }
            {endIcon}
          </OpenPopupLabel>
        </button>
      </StyledPopupButtonDiv>

    )
  };

export default PopupButton;
