import React from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

const MsgBubbleIcon = (props: any) => { 

  return (
    <ChatIcon popupSize={props.popupSize}>
      <FontAwesomeIcon
        icon={faComment}
        role="presentation"
        title="Start Conversation"
      />
    </ChatIcon>
  )
}

const ChatIcon = styled.div<{ popupSize?: string }>`
& > svg.fa-comment {
  vertical-align: baseline;
  height: ${({popupSize}) => { 
    switch ( popupSize ) { 
      case "medium" : 
        return "16px"
      case "large" : 
        return "24px"
      default : 
        return "12px"
    }
  }};
  width: ${({popupSize}) => { 
    switch ( popupSize ) { 
      case "medium" : 
        return "16px"
      case "large" : 
        return "24px"
      default : 
        return "12px"
    }
  }};
} 
`;

export default MsgBubbleIcon
